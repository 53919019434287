import { first, split, get } from "lodash-es";

export const sectionName = {
  'actsvr/AcceptanceDetailView': 'acceptance',
  'elkp/ContractDetailView': 'contract',
  'elkp/ContractLinkView': 'employee',
  'elkp/EmployeeLinkView': 'contract',
  'elkp/EmployeeDetailView': 'employee',
  'inspect/InspectorDetailView': 'inspector',
  'inspect/ApplicationDetailView': 'application',
  'inspect/AppReportCommentsView': 'application',
  'inspect/ApplicationCommentsView': 'application',
  'inspect/ApplicationInspectorBindingListView': 'application',
  'pkomain/CounterpartyDetailView': 'counterparty',
  'pkopir/CounterpartyDetailView': 'counterparty',
  'pkosmr/SurveyDetailView': 'id',
  'pkosmr/CounterpartyDetailView': 'counterparty',
  'pkosmr/CounterpartyCommentsView': 'counterparty',
  'spa/ApplicationDetailView': 'application',
}
export const getSectionEntity = (route) => {
  const name = get(route, 'name', '');
  const section = first(split(name, '/'));
  const key = get(sectionName, name, '');
  const entity = get(route, ['params', key]);
  return { section, entity };
}