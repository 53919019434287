var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('notifications',{staticClass:"mt-3",attrs:{"position":['center', 'top'],"width":_vm.$vuetify.breakpoint.name === 'xs' ? '100%': '50%'},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var title = ref_item.title;
var text = ref_item.text;
var type = ref_item.type;
var state = ref_item.state;
var speed = ref_item.speed;
var length = ref_item.length;
var data = ref_item.data;
var close = ref.close;
return [_c('VAlert',{attrs:{"type":type,"dense":"","dismissible":"","icon":false},on:{"close":close}},[_c('div',{staticClass:"v-messages"},[(title)?_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v(_vm._s(title))]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(text)}})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }